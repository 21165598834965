import React from 'react';
import { Facebook, Linkedin, Instagram, Twitter,Envelope,TelephoneFill } from 'react-bootstrap-icons';

const Footer = () => {
    return (
        <footer className="footer py-5 bg-black text-light">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Protectors Security Services Ltd</h2>
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                            <li>29 Marlow Road, Southall, LONDON, UB2 4NS, ENGLAND, UNITED KINGDOM</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul style={{ listStyle: 'none',padding: 0 }}>
                            <li><a href="mailto:info@protectorssecurityservices.co.uk" className="text-light text-decoration-none"><Envelope/> info@protectorssecurityservices.co.uk</a></li>
                            <li><TelephoneFill/> +44-7397635377 </li>
                            <li><TelephoneFill/> +44-7791610909 </li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6 d-flex justify-content-center">
                        <ul className="social-icons d-flex justify-content-start" style={{ listStyle: 'none', padding: 0 }}>
                            <li className="me-3"><a href="https://www.facebook.com/share/r6Y2ZgBe7qZgt2Xs/ " className="text-light"><Facebook size={24} /></a></li>
                            <li className="me-3"><a href="https://x.com/i/flow/login?redirect_after_login=%2Fservices89246" className="text-light"><Twitter size={24} /></a></li>
                            <li className="me-3"><a href="https://www.linkedin.com/in/protectors-security-services-a5b899328?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className="text-light"><Linkedin size={24} /></a></li>
                            <li className="me-3"><a href=" https://www.instagram.com/protectors_security_services?utm_source=qr&igsh=MWI0MmZzajduN2xzNw==
" className="text-light"><Instagram size={24} /></a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <p>Copyright © 2024 Protectors Security Services Ltd - All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
