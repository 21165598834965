import React from "react";
import H1 from "../assets/H1.webp";
import ContactForm from "./ContactForm";
import Review from "../components/Review";
const Homepage = () => {
  return (
    <>
      <section className="home-banner bg-black">
        <div className="container-fluid">
          <div className="row flex-wrap flex-column flex-sm-column flex-md-row">
            <div className="col px-0">
              <img src={H1} alt="home-img" className="img-fluid" />
            </div>
            <div className="col d-flex align-items-center ">
              <div className="text-light">
                <h1>Your GATEWAY to a different security company</h1>
                <h3>
                  Door Supervisor <br />
                  Manned Guarding <br />
                  Security Services <br />
                  Event Management
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <h1 style={{ color: "#0668FB", paddingTop: "3rem" }}>
              What We Offers
            </h1>
            <p>
              At <b>PROTECTORS SECURITY SERVICES LTD</b>, we specialize in
              comprehensive security solutions that are tailored to meet the
              unique challenges of each client. Our diverse range of services
              includes:
              <br />
              <b>Retail Security: </b> We understand the unique challenges that
              retail businesses face, from theft and shoplifting to managing
              customer safety. Our highly visible, approachable, and trained
              personnel provide a safe shopping environment while protecting
              your assets.
              <br />
              <b>Static Security:</b> Whether you need security for a
              residential building, office complex, or industrial site, our
              static security guards are on hand to offer 24/7 protection,
              ensuring that your property remains secure at all times.
              <br />
              <b>Event Security:</b> From large-scale festivals to intimate
              gatherings, our event security teams are adept at managing crowds,
              monitoring for potential threats, and ensuring that every event
              runs smoothly and securely.
              <br />
              <b>Pubs and Bars Security:</b> Our licensed security staff are
              trained to handle the unique challenges of pubs and bars, from
              managing entry and maintaining order to diffusing conflict and
              ensuring a safe, enjoyable environment for your patrons.
              <br />
              <b>Construction Site Security:</b> Protecting construction sites
              from theft, vandalism, and unauthorized access is crucial. Our
              construction site security services include round-the-clock
              surveillance, access control, and mobile patrols to safeguard your
              valuable equipment and materials. <br />
              <b>Vacant Property Security:</b> Vacant properties are at a higher
              risk of intrusion, vandalism, and damage. We offer a range of
              vacant property security services, including regular inspections,
              CCTV monitoring, and alarm response to keep your property safe.
            </p>
          </div>
          <h1 style={{ color: "#0668FB" }}>Why Choose Us</h1>
          <p>
            At <b>PROTECTORS SECURITY SERVICES LTD</b>, we take a proactive
            approach to security. We don’t just react to incidents; we
            anticipate and prevent them. Our team is dedicated to continuous
            training and professional development, ensuring that we stay ahead
            of emerging threats and industry standards. We utilize the latest
            technology and techniques to provide a seamless and effective
            security experience, tailored specifically to the needs of our
            clients. <br />
            Our commitment to excellence has earned us a reputation for
            reliability, discretion, and outstanding service. We are proud to be
            a trusted security partner for businesses, events, and properties
            across the UK.
          </p>
          <h1 style={{ color: "#0668FB" }}>Fully Licensed</h1>
          <p>
            Company registered in England and Wales - Registration Number:
            15925377
          </p>
          <p style={{fontSize: '12px'}}>
            Registered Office Address: 29 Marlow Road, Southall, London, Ub2 4Ns, England,
            United Kingdom
          </p>
        </div>
      </section>
      <Review/>
      <ContactForm />
    </>
  );
};
export default Homepage;
